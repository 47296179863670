import moment from "moment/moment";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import { NumberFormatAndAddZero } from "../../../../helpers/capatalizeLetters";
import { getData } from "../../../../services";
import { downloadReport } from "../../../../services/downloadService";
import { transactionTypeReports } from "../../../../utils/staticData";
import CustomButton from "../../../atoms/CustomButton";
import { Heading } from "../../../atoms/Heading";
import NoContentCard from "../../../atoms/NoContentCard";
import Pagination from "../../../atoms/Pagination";
import Search from "../../../atoms/Search";
import Upload from "../../../icons/Upload";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import style from "./index.module.scss";

const ReportsDue = () => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Reports;
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [portfolioData, setPortfolioData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loader, setLoader] = useState(true);
  const [clientListData, setClientListData] = useState([]);
  const [obligorListData, setObligorListData] = useState([]);

  const getFundingData = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "funding/getAllFundings",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          status: ["Funded"],
          transaction_type: filters?.["Transaction Type"],
          clientName: filters?.["Client"],
          obligorName: filters?.["Obligor"],
          dueStartDate: filters?.startDate0,
          dueEndDate: filters?.endDate0,
          fundingStartDate: filters?.startDate1,
          fundingEndDate: filters?.endDate1,
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        },
      });
      setPortfolioData(res?.data);
    } catch (err) {
      console.log("errr", err);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    setLoader(false);
    getFundingData();
  }, [itemsPerPage, currentPage, searchData, filters]);

  const getClientList = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getDataWithCounterType",
        token: cookies.t,
        params: {
          conterparty_type: "Client",
        },
      });
      setClientListData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const getObligorList = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getDataWithCounterType",
        token: cookies.t,
        params: {
          conterparty_type: "Obligor",
        },
      });
      setObligorListData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getClientList();
    getObligorList();
  }, []);

  const columns = [
    {
      field: "client_name",
      headerName: "Client Name",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return <div className="line-truncate">{params?.data?.client_name}</div>;
      },
    },
    {
      field: "obligor_name",
      headerName: "Obligor Name",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div className="line-truncate">
            {params?.data?.client_id === params?.data?.obligor_id
              ? params?.data?.client_name
              : params?.data?.obligor_name
              ? params?.data?.obligor_name
              : params?.data?.client_name
              ? params?.data?.client_name
              : ""}
          </div>
        );
      },
    },
    {
      field: "supplier_name",
      headerName: "Supplier Name",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div className="line-truncate">
            {params?.data?.client_id === params?.data?.supplier_id
              ? params?.data?.client_name
              : params?.data?.supplier_name
              ? params?.data?.supplier_name
              : params?.data?.client_name
              ? params?.data?.client_name
              : ""}
          </div>
        );
      },
    },
    {
      field: "funder",
      headerName: "Funder",
      flex: 1,
      sortable: true,
    },
    {
      field: "Due Date",
      headerName: "Due Date",
      flex: 1,
      sortable: true,
      valueGetter: (params) => params?.data?.due_date || null,
      valueFormatter: (params) =>
        params.value ? moment.utc(params.value).format("YYYY-MM-DD") : "",
      cellRenderer: (params) => (
        <div className={style.valueStyle}>
          {params.value ? moment.utc(params.value).format("YYYY-MM-DD") : ""}
        </div>
      ),
    },
    {
      field: "Client Reference",
      headerName: "Client Reference",
      flex: 1,
      sortable: true,
      filterable: true,
      valueGetter: (params) => {
        return params?.data?.transaction_type !== "Note" &&
          params?.data?.transaction_type !== "Revolver" &&
          params?.data?.transaction_type !== "Client"
          ? params?.data?.invoice_number
          : params?.data?.referance;
      },
      cellRenderer: (params) => {
        const value = params.value;
        return (
          <div className={style.valueStyle} title={value}>
            {value}
          </div>
        );
      },
    },

    {
      field: "currency",
      headerName: "Currency",
      flex: 1,
      sortable: true,
    },
    {
      field: "cost_value",
      headerName: "Cost Value",
      type: "number",
      sortable: true,
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => Number(params?.data?.cost_value) || 0,
      valueFormatter: (params) =>
        params.value ? NumberFormatAndAddZero(params.value) : "0",
      cellRenderer: (params) => (
        <div className={style.valueStyle}>
          {NumberFormatAndAddZero(params.value)}
        </div>
      ),
    },
    {
      field: "face_value",
      headerName: "Face value",
      flex: 1,
      sortable: true,
      type: "number",
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => Number(params?.data?.face_value) || 0,
      valueFormatter: (params) =>
        params.value ? NumberFormatAndAddZero(params.value) : "0",
      cellRenderer: (params) => (
        <div className={style.valueStyle}>
          {NumberFormatAndAddZero(params.value)}
        </div>
      ),
    },
    {
      field: "funded_date",
      headerName: "Funding Date",
      flex: 1,
      sortable: true,
      valueGetter: (params) => params?.data?.funded_date || null,
      valueFormatter: (params) =>
        params.value ? moment.utc(params.value).format("YYYY-MM-DD") : "",
      cellRenderer: (params) => (
        <div className={style.valueStyle}>
          {params.value ? moment.utc(params.value).format("YYYY-MM-DD") : ""}
        </div>
      ),
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div
            title={
              params.data?.transaction_type === "Obligor"
                ? "Factoring/AR"
                : params.data?.transaction_type
            }
          >
            {params.data?.transaction_type === "Obligor"
              ? "Factoring/AR"
              : params.data?.transaction_type}
          </div>
        );
      },
    },
  ];

  const CustomNoRowsOverlay = memo(() => {
    return (
      !loader && (
        <>
          <NoContentCard
            title="No Due created yet!"
            tag="Add Counterparties"
            type="Counterparty"
            classes="d-flex align-items-center"
          />
        </>
      )
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const filtersData = {
    "Transaction Type": transactionTypeReports,
    Client: clientListData,
    Obligor: obligorListData,
  };

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "funding/exportDue",
      token: cookies.t,
      fileName: "Due",
      params: {
        search: searchData,
        status: ["Funded"],
        transaction_type: filters?.["Transaction Type"],
        clientName: filters?.["Client"],
        obligorName: filters?.["Obligor"],
        dueStartDate: filters?.startDate0,
        dueEndDate: filters?.endDate0,
        fundingStartDate: filters?.startDate1,
        fundingEndDate: filters?.endDate1,
        group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
      },
    });

    if (res) {
      toast.success("Due Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Due</Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            placeholder="Search by name"
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />

          {roleData && roleData?.export && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}
          <FilterComponent
            filterData={filtersData}
            isDateRange
            dateRangeNames={["Due Date", "Funding Date"]}
            onFilterChange={(filters) => {
              setFilters(filters);
            }}
          />
        </div>
      </div>

      <CustomDatagrid
        rows={portfolioData?.[0]?.fundingData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        loader={loader}
        isRowsVisible
        isValueVisible
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={portfolioData?.[0]?.pageDetails?.count}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </>
  );
};

export default ReportsDue;
