import React, { memo, useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import { NumberFormatAndAddZero } from "../../../../helpers/capatalizeLetters";
import { getData } from "../../../../services";
import { downloadReport } from "../../../../services/downloadService";
import {
  agreementCounterPartyData,
  counterPartyType,
  CurrencyData,
  GICSectorData,
  statusData,
  transactionTypeReports,
} from "../../../../utils/staticData";
import CustomButton from "../../../atoms/CustomButton";
import { Heading } from "../../../atoms/Heading";
import { DateSelector } from "../../../atoms/MonthYearSelector";
import MyDropdown from "../../../atoms/MyDropdown";
import NoContentCard from "../../../atoms/NoContentCard";
import Pagination from "../../../atoms/Pagination";
import Upload from "../../../icons/Upload";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import style from "./index.module.scss";

const ReportsExp = () => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Counterparties;
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [counterpartyData, setCounterPartData] = useState([]);
  const [investorData, setInvestorData] = useState([]);
  const [formData, setFormData] = useState({
    start_date: "",
    end_date: "",
    currency: "",
    internal_financing_company: "",
    fund: "",
  });
  const [filters, setFilters] = useState({});
  const [accuralData, setAccuralData] = useState();
  const [loader, setLoader] = useState(true);

  //   const getCounterPartyData = async () => {
  //     try {
  //       const res = await getData({
  //         endpoint: "couterParty/getAllCounters",
  //         token: cookies.t,
  //         params: {
  //           page: currentPage,
  //           limit: itemsPerPage,
  //           search: searchData,
  //           counterType: filters?.["Counterparty Type"],
  //           status: filters?.["Status"],
  //           transactionType: filters?.["Transaction Type"],
  //           sector: filters?.["Sector"],
  //           group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
  //         },
  //       });
  //       setCounterPartData(res?.data);
  //     } catch (err) {
  //       console.log("errr", err);
  //     }
  //   };
  //   useEffect(() => {
  //     getCounterPartyData();
  //   }, [itemsPerPage, currentPage, searchData, filters]);

  const getInvestorData = async () => {
    try {
      const res = await getData({
        endpoint: "investor/getInvestorList",
        token: cookies.t,
      });
      setInvestorData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    setLoader(false);
    getInvestorData();
  }, []);

  const columns = [
    {
      field: "principle",
      headerName: "Principle",
      children: [
        {
          field: "date",
          headerName: "Date",
          flex: 1,
          cellRenderer: (params) => {
            return <div className={style.valueStyle}>{params?.data?.date}</div>;
          },
        },
        {
          field: "principle_remaining",
          headerName: "Principal Remaining",
          flex: 1,
          sortable: true,
          cellRenderer: (params) => {
            return (
              <>
                <span>
                  {NumberFormatAndAddZero(
                    params?.data?.principle_remaining?.toFixed(2),
                  )}
                </span>
              </>
            );
          },
        },
        {
          field: "priciple_paid",
          headerName: "Principal Paid",
          flex: 1,
          sortable: true,
          cellRenderer: (params) => {
            return (
              <>
                <span>
                  {NumberFormatAndAddZero(
                    params?.data?.priciple_paid?.toFixed(2),
                  )}
                </span>
              </>
            );
          },
        },
      ],
    },
    {
      field: "intrest",
      headerName: "Intrest",
      children: [
        {
          field: "interest_paid",
          headerName: "Interest Paid",
          flex: 1,
          sortable: true,
          cellRenderer: (params) => {
            return (
              <>
                <span>
                  {NumberFormatAndAddZero(
                    params?.data?.interest_paid?.toFixed(2),
                  )}
                </span>
              </>
            );
          },
        },
        {
          field: "interest_accured",
          headerName: "Interest Accrued",
          flex: 1,
          sortable: true,
          cellRenderer: (params) => {
            return (
              <>
                <span>
                  {NumberFormatAndAddZero(
                    params?.data?.interest_accured?.toFixed(2),
                  )}
                </span>
              </>
            );
          },
        },
        {
          field: "amount_to_close",
          headerName: "Amount to Close",
          flex: 1,
          sortable: true,
          cellRenderer: (params) => {
            return (
              <>
                <span>
                  {NumberFormatAndAddZero(
                    params?.data?.amount_to_close?.toFixed(2),
                  )}
                </span>
              </>
            );
          },
        },
      ],
    },
    {
      field: "primary_platform",
      headerName: "Primary (Platform)",
      children: [
        {
          field: "primary_fee_accured",
          headerName: "Fee Accured",
          flex: 1,
          sortable: true,
          cellRenderer: (params) => {
            return (
              <>
                <span>
                  {NumberFormatAndAddZero(
                    params?.data?.primary_fee_accured?.toFixed(2),
                  )}
                </span>
              </>
            );
          },
        },
        {
          field: "primary_fee_earned",
          headerName: "Fee Earned",
          flex: 1,
          sortable: true,
          cellRenderer: (params) => {
            return (
              <>
                <span>
                  {NumberFormatAndAddZero(
                    params?.data?.primary_fee_earned?.toFixed(2),
                  )}
                </span>
              </>
            );
          },
        },
      ],
    },
    {
      field: "secondary_platform",
      headerName: "Secondary (Brokers)",
      children: [
        {
          field: "secondary_fee_accured",
          headerName: "Fee Accured",
          flex: 1,
          sortable: true,
          cellRenderer: (params) => {
            return (
              <>
                <span>
                  {NumberFormatAndAddZero(
                    params?.data?.secondary_fee_accured?.toFixed(2),
                  )}
                </span>
              </>
            );
          },
        },
        {
          field: "secondary_fee_earned",
          headerName: "Fee Earned",
          flex: 1,
          sortable: true,
          cellRenderer: (params) => {
            return (
              <>
                <span>
                  {NumberFormatAndAddZero(
                    params?.data?.secondary_fee_earned?.toFixed(2),
                  )}
                </span>
              </>
            );
          },
        },
      ],
    },
  ];

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title="No Accrual(Exp) created yet!"
          tag="Add Counterparties"
          type="Counterparty"
          classes="d-flex align-items-center"
        />
      </>
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const handleGetAccuralDaily = async () => {
    setAccuralData(null);
    try {
      const res = await getData({
        endpoint: "funding/getAllAccuralExy",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          start_date: formData?.start_date,
          end_date: formData?.end_date,
          fund: formData?.fund,
          currency: formData?.currency,
          internal_financing_company: formData?.internal_financing_company,
        },
      });
      setAccuralData(res?.data);
    } catch (err) {
      console.log("errr", err);
    }
  };

  const filtersData = {
    "Counterparty Type": counterPartyType,
    "Transaction Type": transactionTypeReports,
    Status: statusData,
    Sector: GICSectorData,
  };

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "funding/exportExy",
      token: cookies.t,
      fileName: "Accural(Exy)",
      params: {
        start_date: formData?.start_date,
        end_date: formData?.end_date,
        fund: formData?.fund,
        currency: formData?.currency,
        internal_financing_company: formData?.internal_financing_company,
      },
    });

    if (res) {
      toast.success("Accural(Exy) Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className="mb-3">
        <Heading className="HeadingStyle">Accrual(Exp)</Heading>
      </div>
      <div className="d-flex gap-3 w-100 flex-wrap mb-4">
        <div
          style={{
            width: "30%",
            position: "relative",
          }}
        >
          <DateSelector
            placeholder="Start Date"
            onChange={(option) => {
              setFormData({
                ...formData,
                start_date: option,
              });
            }}
            selectedDate={formData.start_date}
          />
        </div>
        <div
          style={{
            width: "30%",
            position: "relative",
          }}
        >
          <DateSelector
            placeholder="End Date"
            onChange={(option) => {
              setFormData({
                ...formData,
                end_date: option,
              });
            }}
            selectedDate={formData.end_date}
          />
        </div>
        <div
          style={{
            width: "30%",
            position: "relative",
          }}
        >
          <MyDropdown
            data={CurrencyData}
            placeholder="Currency"
            onChange={(option) => {
              setFormData({
                ...formData,
                currency: option,
              });
            }}
            selectedValue={formData.currency}
          />
        </div>
        <div
          style={{
            width: "30%",
            position: "relative",
          }}
        >
          <MyDropdown
            data={agreementCounterPartyData}
            placeholder="Internal Financing Company"
            onChange={(option) => {
              setFormData({
                ...formData,
                internal_financing_company: option,
              });
            }}
            selectedValue={formData.internal_financing_company}
          />
        </div>
        <div
          style={{
            width: "30%",
            position: "relative",
          }}
        >
          <MyDropdown
            data={investorData}
            placeholder="Select Fund"
            onChange={(option) => {
              setFormData({
                ...formData,
                fund: option,
              });
            }}
            selectedValue={formData.fund}
          />
        </div>
        <div>
          <CustomButton
            text="Confirm Selection"
            type="btn-outline-primary"
            size="btn-lg"
            style={{ minWidth: "max-content" }}
            handleClick={() => handleGetAccuralDaily()}
          />
        </div>
      </div>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Accrual(Exp) List</Heading>
        </div>
        <div className={style.searchButton}>
          {/* <Search
            placeholder="Search by name"
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          /> */}

          {roleData && roleData?.export && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}
          {/* <FilterComponent
            filterData={filtersData}
            onFilterChange={(filters) => {
              setFilters(filters);
            }}
          /> */}
        </div>
      </div>

      <CustomDatagrid
        rows={accuralData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        isRowsVisible
        isValueVisible
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {/* {counterpartyData?.[0]?.pageDetails?.count > 10 && ( */}
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={counterpartyData?.[0]?.pageDetails?.count}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
      {/* )} */}
    </>
  );
};

export default ReportsExp;
