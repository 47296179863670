import React, { memo, useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { NumberFormatAndAddZero } from "../../../../../helpers/capatalizeLetters";
import { getData } from "../../../../../services";
import {
  getCurrencyRate,
  getCurrencyRateEuro,
  getCurrencyRateGBP,
} from "../../../../../services/currencyConvertor";
import NoContentCard from "../../../../atoms/NoContentCard";
import Pagination from "../../../../atoms/Pagination";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";

const ClientSummary = ({ date, currencyConversion }) => {
  const [loader, setLoader] = useState(true);
  const [cookies] = useCookies(["t"]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Dashboard;
  const [conversionRateEURO, setConversionRateEURO] = useState(null);
  const [conversionRateGBP, setConversionRateGBP] = useState(null);
  const [conversionRateUSD, setConversionRateUSD] = useState(null);

  const getClientData = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "dashboard/getClientSummary",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
          start_date: date?.start_date?.replace(".000", ""),
          end_date: date?.end_date?.replace(".000", ""),
        },
      });

      setData(res?.data);
    } catch (err) {
    } finally {
      setLoader(false);
    }
  };
  console.log("data", data);

  useEffect(() => {
    setLoader(false);
    getClientData();
  }, [itemsPerPage, currentPage, date]);

  useEffect(() => {
    const fetchCurrencyRateEURO = async () => {
      try {
        const res = await getCurrencyRateEuro({ date: new Date() });
        setConversionRateEURO(res);
      } catch (error) {
        console.error("Error fetching currency rate:", error);
      }
    };
    const fetchCurrencyRateGBP = async () => {
      try {
        const res = await getCurrencyRateGBP({ date: new Date() });
        setConversionRateGBP(res);
      } catch (error) {
        console.error("Error fetching currency rate:", error);
      }
    };
    const fetchCurrencyRate = async () => {
      try {
        const res = await getCurrencyRate({ date: new Date() });
        setConversionRateUSD(res);
      } catch (error) {
        console.error("Error fetching currency rate:", error);
      }
    };

    fetchCurrencyRate();

    fetchCurrencyRateEURO();
    fetchCurrencyRateGBP();
  }, []);

  const getConvertedCreditLimit = ({
    cost_value,
    primary_currency,
    conversionRateUSD,
    conversionRateGBP,
    conversionRateEURO,
  }) => {
    if (!cost_value || !Array.isArray(cost_value)) {
      console.error("Invalid cost_value data");
      return 0;
    }

    if (!conversionRateUSD) {
      console.error("currencyConversion is required");
      return 0;
    }

    let result = 0;
    if (primary_currency === "USD") {
      cost_value.forEach((item) => {
        if (item?.currency === "EUR") {
          result += item?.cost_value * conversionRateEURO?.usd;
        } else if (item?.currency === "GBP") {
          result += item?.cost_value * conversionRateGBP?.usd;
        } else {
          result += item?.cost_value; // Assuming USD doesn't need conversion
        }
      });

      return result;
    } else if (primary_currency === "EUR") {
      cost_value.forEach((item) => {
        if (item?.currency === "USD") {
          result += item?.cost_value * conversionRateUSD?.eur;
        } else if (item?.currency === "GBP") {
          result += item?.cost_value * conversionRateGBP?.eur;
        } else {
          result += item?.cost_value; // Assuming USD doesn't need conversion
        }
      });

      return result;
    } else if (primary_currency === "GBP") {
      cost_value.forEach((item) => {
        if (item?.currency === "EUR") {
          result += item?.cost_value * conversionRateEURO?.gbp;
        } else if (item?.currency === "USD") {
          result += item?.cost_value * conversionRateUSD?.gbp;
        } else {
          result += item?.cost_value; // Assuming USD doesn't need conversion
        }
      });

      return result;
    }
  };

  const columns = [
    {
      field: "client_name",
      headerName: "Client",
      flex: 0.8,
      sortable: true,
      cellRenderer: (params) => {
        return <div className="line-truncate">{params?.data?.client_name}</div>;
      },
    },
    {
      field: "currency",
      headerName: "Currency",
      // sortable : true,
      flex: 0.8,
      cellRenderer: (params) => {
        const primary_currency = params?.data?.primary_currency;
        return (
          <>
            <span>{primary_currency}</span>
          </>
        );
      },
    },
    {
      field: "client_internal_limit",
      headerName: "Credit Limit",
      flex: 0.8,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <>
            <span>
              {params?.data?.client_internal_limit &&
                NumberFormatAndAddZero(params?.data?.client_internal_limit)}
            </span>
          </>
        );
      },
    },
    ...(roleData?.tabs
      ? [
          {
            field: "Credit Insured Amount",
            headerName: "Credit Insured Amount",
            flex: 0.8,
          },
        ]
      : []),
    {
      field: "totalFaceValue",
      headerName: "Credit Used",
      flex: 0.8,
      cellRenderer: (params) => {
        const creditLimitCostDetails = getConvertedCreditLimit({
          cost_value: params?.data?.costDetails,
          primary_currency: params?.data?.primary_currency,
          conversionRateUSD: conversionRateUSD,
          conversionRateGBP: conversionRateGBP,
          conversionRateEURO: conversionRateEURO,
        });
        return (
          <>
            <span>{NumberFormatAndAddZero(creditLimitCostDetails)}</span>
          </>
        );
      },
    },
    {
      field: "Credit Remaining",
      headerName: "Credit Remaining",
      sortable: true,
      flex: 0.8,
      valueGetter: (params) => {
        const cost = parseFloat(params?.data?.costSummary?.USD) || 0;
        const internalLimit =
          parseFloat(params?.data?.client_internal_limit) || 0;
        return internalLimit - cost;
      },
      cellRenderer: (params) => {
        const creditLimitCostDetails = getConvertedCreditLimit({
          cost_value: params?.data?.costDetails,
          primary_currency: params?.data?.primary_currency,
          conversionRateUSD: conversionRateUSD,
          conversionRateGBP: conversionRateGBP,
          conversionRateEURO: conversionRateEURO,
        });
        return (
          <>
            <span>
              {parseFloat(creditLimitCostDetails) > 0
                ? NumberFormatAndAddZero(
                    parseFloat(params?.data?.client_internal_limit) -
                      parseFloat(creditLimitCostDetails),
                  )
                : 0}
            </span>
          </>
        );
      },
    },
  ];

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title={"No Data"}
          // subtitle={content[type].subtitle}
          type="Pricing"
          classes="d-flex align-items-center"
          height={"7rem"}
          fontSize={"20px"}
        />
      </>
    );
  });
  return (
    <div>
      <CustomDatagrid
        columns={columns}
        rows={data?.data || []}
        getRowId={(row) => row?._id}
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        tHeight="340px"
        smallTable
      />
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={data?.totalCount}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
        options={["5", "10", "15"]}
      />
    </div>
  );
};

export default ClientSummary;
