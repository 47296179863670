import React, { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import NoContentCard from "../../../../atoms/NoContentCard";
import Pagination from "../../../../atoms/Pagination";
import HorizontalMenu from "../../../../icons/HorizontalMenu";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";

import moment from "moment/moment";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/components/toast";
import { NumberFormatAndAddZero } from "../../../../../helpers/capatalizeLetters";
import { deleteData, getData, patchData } from "../../../../../services";
import { getPandaApi } from "../../../../../services/pandaDocs";
import Modal from "../../../../atoms/modals/DeleteModal";
import ArchiveIcon from "../../../../icons/Archive";
import Delete from "../../../../icons/Delete";
import Refresh from "../../../../icons/Refresh";
import Signature from "../../../../icons/Signature";
import View from "../../../../icons/View";
import CustomMenu from "../../../../molecules/CustomMenu";

const content = {
  Draft: {
    title: "No Funding added for Review yet!",
    subtitle: "Currently, no funding has been added for review.",
  },
  "Advance Request": {
    title: "No Funding added in Advance Request yet!",
    subtitle: "Currently, no funding has been added to Advance Request.",
  },
  Submitted: {
    title: "No Funding added in Submitted yet!",
    subtitle: "Currently, no funding are Submitted.",
  },
  Approved: {
    title: "No Funding Approved yet!",
    subtitle: "Currently, no funding have been Approved for processing.",
  },
  Allocated: {
    title: "No Funding Allocated yet!",
    subtitle: "Currently, no funding have been Allocated.",
  },
  Funded: {
    title: "No Funding Funded yet!",
    subtitle: "Currently, no funding have been Funded.",
  },
  Closed: {
    title: "No Funding Closed yet!",
    subtitle: "Currently, no funding have been Closed.",
  },
};

const Table = ({
  type,
  searchData,
  setModalStateAdvReq,
  setModalStateSubmitted,
  setModalStateApproved,
  setModalStateAllocated,
  filters,
  getAllFundingStatus,
  roleData,
  modalStateAdvReq,
  modalStateSubmitted,
  modalStateAllocated,
  modalStateApproved,
}) => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(400);
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalStateArchive, setModalStateArchive] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });
  const [loader, setLoader] = useState(false);

  const [pandaDocStatus, setPandaDocStatus] = useState([]);

  const [fundingData, setFundingData] = useState([]);

  const GetFundingData = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "funding/getAllFundings",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          status: [type],
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
          transaction_type:
            filters?.["Transaction Type"] &&
            filters?.["Transaction Type"]?.length
              ? filters?.["Transaction Type"]
              : null,
          currency: filters?.["Currency"]?.length
            ? filters?.["Currency"]
            : null,
          investor_type: filters?.["Funder"],
          clientName: filters?.["Client"],
          obligorName: filters?.["Obligor"],
          start_date: filters?.startDate0,
          end_date: filters?.endDate0,
        },
      });
      getAllFundingStatus();
      setFundingData(res?.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    GetFundingData();
  }, [
    itemsPerPage,
    currentPage,
    searchData,
    type,
    modalStateAdvReq,
    modalStateSubmitted,
    modalStateApproved,
    modalStateAllocated,
    filters,
  ]);

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log("roleData", roleData);

  const menu = [
    (() => {
      let menuItem = [];
      switch (type) {
        case "Draft":
          menuItem.push({
            icon: <View />,
            label: "View",
            onClick: () => {
              navigate(`/dashboard/funding/draft/view/${selectedRow._id}`, {
                state: { tab: type },
              });
            },
          });
          break;
        case "Advance Request":
          // menuItem.push({
          //   icon: <View />,
          //   label: "View",
          //   onClick: () => {
          //     navigate(`/dashboard/funding/view/${selectedRow?._id}`);
          //     handleClose();
          //   },
          // });
          menuItem.push({
            icon: <Signature />,
            label: "Status",
            onClick: () => {
              setModalStateAdvReq(() => ({
                isModalOpen: true,
                modalTitle: "Approval",
                modalId: selectedRow?._id,
              }));
              handleClose();
            },
          });
          break;
        case "Submitted":
          menuItem.push({
            icon: <View />,
            label: "View",
            onClick: () => {
              navigate(`/dashboard/funding/view/${selectedRow?._id}`, {
                state: { tab: type },
              });
            },
          });
          menuItem.push({
            icon: <Signature />,
            label: "Status",
            onClick: () => {
              setModalStateSubmitted(() => ({
                isModalOpen: true,
                modalTitle: "Approval",
                modalId: selectedRow?._id,
              }));
              handleClose();
            },
          });
          break;
        case "Approved":
          menuItem.push({
            icon: <View />,
            label: "View",
            onClick: () => {
              navigate(`/dashboard/funding/view/${selectedRow?._id}`, {
                state: { tab: type },
              });
            },
          });
          menuItem.push({
            icon: <Signature />,
            label: "Status",
            onClick: () => {
              setModalStateApproved(() => ({
                isModalOpen: true,
                modalTitle: "Approval",
                modalId: selectedRow?._id,
              }));
              handleClose();
            },
          });
          break;
        case "Allocated":
          menuItem.push({
            icon: <View />,
            label: "View",
            onClick: () => {
              navigate(`/dashboard/funding/view/${selectedRow?._id}`, {
                state: { tab: type },
              });
            },
          });
          menuItem.push({
            icon: <Signature />,
            label: "Mark as Funded",
            onClick: () => {
              setModalStateAllocated(() => ({
                isModalOpen: true,
                modalTitle: "Mark as Funded",
                modalId: selectedRow?._id,
              }));
              handleClose();
            },
          });
          break;
        case "Funded":
          menuItem.push({
            icon: <View />,
            label: "View",
            onClick: () => {
              navigate(`/dashboard/funding/view/${selectedRow?._id}`, {
                state: { tab: type },
              });
            },
          });
          break;
        case "Closed":
          menuItem.push({
            icon: <View />,
            label: "View",
            onClick: () => {
              navigate(`/dashboard/funding/view/${selectedRow?._id}`, {
                state: { tab: type },
              });
            },
          });
          break;
        case "Archive":
          menuItem.push({
            icon: <Delete />,
            label: "Delete",
            onClick: () => {
              setModalStateDelete(() => ({
                isModalOpen: true,
                modalTitle: selectedRow.client_name,
                modalId: selectedRow?._id,
              }));
              handleClose();
            },
          });
          break;
        default:
          break;
      }
      type !== "Archive" &&
        type !== "Funded" &&
        type !== "Closed" &&
        roleData?.tabs &&
        menuItem.push({
          icon: <ArchiveIcon />,
          label: "Reject",
          onClick: () => {
            setModalStateArchive({
              isModalOpen: true,
              name: selectedRow.client_name,
              modalId: selectedRow._id,
            });
            setAnchorEl(null);
          },
        });
      return menuItem;
    })(),
  ];

  const handleCloseArchive = () => {
    setModalStateArchive(() => ({
      isModalOpen: false,
    }));
  };
  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };

  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: "funding/deleteFunding",
        token: cookies.t,
        params: { id: modalStateDelete?.modalId },
      });
      if (res) {
        toast.success("Funding Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseDelete();
        GetFundingData();
        getAllFundingStatus();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleArchive = async () => {
    try {
      const res = await patchData({
        endpoint: "funding/UpdateFundingStatus",
        token: cookies.t,
        params: {
          id: modalStateArchive?.modalId,
        },
        data: {
          status: "Archive",
        },
      });
      if (res) {
        toast.success("Funding Archived Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseArchive();
        GetFundingData();
        getAllFundingStatus();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handlePandaDocsStatus = async (data) => {
    const response = await getPandaApi({
      endpoint: `https://api.pandadoc.com/public/v1/documents/${data?.documentsInvoice?.docInvoiceId}`,
      params: {},
      token: process.env.REACT_APP_PANDADOC_ID,
    });

    if (response) {
      toast.success("Document Status Refreshed", {
        duration: 1000,
        style: confimationStyles,
      });
    }
    setPandaDocStatus((prev) => {
      const id = data?._id;
      const status = response.status;

      // Check if the ID already exists in the state
      const exists = prev.some((entry) => entry[id] !== undefined);

      if (exists) {
        // Update the existing entry
        return prev.map((entry) =>
          entry[id] !== undefined ? { [id]: status } : entry,
        );
      } else {
        // Add a new entry
        return [...prev, { [id]: status }];
      }
    });

    return response;
  };

  const handleUpdateDocStatus = async ({ data }) => {
    if (!data?._id) {
      console.error("Invalid data: Missing id");
      return;
    }
    const documentStatus = await handlePandaDocsStatus(data);

    if (!documentStatus?.status) {
      console.error("Invalid data: Missing Status");
      return;
    }
    try {
      const res = await patchData({
        endpoint: "/funding/UpdateFundingDocStatus",
        token: cookies.t,
        data: { documentStatus: documentStatus.status },
        params: { id: data._id },
      });

      if (res) {
        GetFundingData();
      } else {
        console.error("Failed to update document: No response data");
      }
    } catch (error) {
      console.error("Error updating document:", error);
    } finally {
      setLoader(false);
    }
  };

  const columns = [
    {
      field: "client_name",
      headerName: "Client",
      flex: 0.8,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.client_name} className="line-truncate">
            {params?.data?.client_name}
          </div>
        );
      },
    },
    {
      field: "obligor_name",
      headerName: "Obligor",
      flex: 0.8,
      sortable: true,
      valueGetter: (params) => {
        return params?.data?.obligor_name
          ? params?.data?.obligor_name
          : params?.data?.client_name;
      },
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.obligor_name} className="line-truncate">
            {params?.data?.obligor_name
              ? params?.data?.obligor_name
              : params?.data?.client_name}
          </div>
        );
      },
    },
    {
      field: "supplier_name",
      headerName: "Supplier",
      flex: 0.8,
      // sortable: true,
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.supplier_name} className="line-truncate">
            {params?.data?.supplier_name
              ? params?.data?.supplier_name
              : params?.data?.client_name}
          </div>
        );
      },
    },
    ...(type === "Allocated"
      ? [
          {
            field: "funder",
            headerName: "Funder",
            flex: 0.8,
            // cellRenderer: (params) => {
            //   return <div>{params?.data?.funder && params?.data?.funder}</div>;
            // },
          },
        ]
      : []),
    ...(type === "Draft" || type === "Advance Request" || type === "Closed"
      ? [
          {
            field: "referance",
            headerName: "Client Ref",
            flex: 0.8,
            cellRenderer: (params) => {
              return (
                <div>
                  {params?.data?.invoice_number || params?.data?.referance}
                </div>
              );
            },
          },
        ]
      : []),
    {
      field: "cost_value",
      headerName: "Cost Value",
      flex: 0.8,
      sortable: true,
      valueGetter: (params) => Number(params?.data?.cost_value) || 0,
      cellRenderer: (params) => {
        return (
          <div>
            {NumberFormatAndAddZero(parseFloat(params?.data?.cost_value))}
          </div>
        );
      },
    },
    {
      field: "face_value",
      headerName: "Face Value",
      flex: 0.8,
      sortable: true,
      valueGetter: (params) => Number(params?.data?.face_value) || 0,
      cellRenderer: (params) => {
        return (
          <div>
            {NumberFormatAndAddZero(parseFloat(params?.data?.face_value))}
          </div>
        );
      },
    },
    ...(type === "Closed"
      ? [
          {
            field: "paymentHistory",
            headerName: "Closing Amount",
            flex: 0.8,
            cellRenderer: (params) => {
              const totalAmount = (params?.data?.paymentHistory ?? []).reduce(
                (total, payment) => {
                  const amount = parseFloat(payment?.paymentAmount); // Convert to number
                  return total + (isNaN(amount) ? 0 : amount); // Add only valid numbers
                },
                0,
              );
              return <div>{totalAmount}</div>;
            },
          },
        ]
      : []),
    ...(type === "Funded"
      ? [
          {
            field: "paymentHistory",
            headerName: "Partial Amount",
            flex: 0.8,
            cellRenderer: (params) => {
              const totalAmount = (params?.data?.paymentHistory ?? []).reduce(
                (total, payment) => {
                  const amount = parseFloat(payment?.paymentAmount); // Convert to number
                  return total + (isNaN(amount) ? 0 : amount); // Add only valid numbers
                },
                0,
              );
              return <div>{totalAmount}</div>;
            },
          },
        ]
      : []),
    ...(type === "Advance Request" ||
    type === "Submitted" ||
    type === "Approved" ||
    type === "Allocated" ||
    type === "Funded"
      ? [
          {
            field: "invoice_date",
            headerName: "Invoice Date",
            flex: 0.8,
            sortable: true,
            cellRenderer: (params) => {
              return (
                <div>
                  {params?.data?.invoice_date
                    ? moment
                        .utc(params?.data?.invoice_date)
                        .format("YYYY-MM-DD")
                    : ""}
                </div>
              );
            },
          },
          {
            field: "due_date",
            headerName: "Due Date",
            flex: 0.8,
            sortable: true,
            cellRenderer: (params) => {
              return (
                <div>
                  {params?.data?.due_date &&
                    moment.utc(params?.data?.due_date).format("YYYY-MM-DD")}
                </div>
              );
            },
          },
        ]
      : []),
    ...(type === "Funded"
      ? [
          {
            field: "funded_date",
            headerName: "Funding Date",
            flex: 0.8,
            sortable: true,
            cellRenderer: (params) => {
              return (
                <div>
                  {params?.data?.funded_date &&
                    moment.utc(params?.data?.funded_date).format("YYYY-MM-DD")}
                </div>
              );
            },
          },
          {
            field: "funder",
            headerName: "Funder",
            flex: 0.8,
            sortable: true,
            cellRenderer: (params) => {
              return (
                <div>
                  {params?.data?.funder
                    ? params?.data?.funder?.toUpperCase()
                    : "--"}
                </div>
              );
            },
          },
        ]
      : []),
    ...(type === "Draft"
      ? [
          {
            field: "payment_terms",
            headerName: "Terms",
            flex: 0.8,
            sortable: true,
            cellRenderer: (params) => {
              return (
                <div>
                  {params?.data?.payment_terms
                    ? `${params?.data?.payment_terms} Days`
                    : "--"}
                </div>
              );
            },
          },
        ]
      : []),
    {
      field: "currency",
      headerName: "Currency",
      flex: 0.6,
      sortable: true,
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      flex: 0.8,
      cellRenderer: (params) => {
        return (
          <div
            title={
              params?.data?.transaction_type === "Obligor"
                ? "Factoring/AR"
                : params?.data?.transaction_type
            }
          >
            {params?.data?.transaction_type === "Obligor"
              ? "Factoring/AR"
              : params?.data?.transaction_type}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.7,
      valueGetter: (params) => {
        return params.data?.status === "Funded" ? "Open" : params.data?.status;
      },
      cellRenderer: (params) => {
        return (
          <div>
            {params?.data?.status === "Funded" ? "Open" : params?.data?.status}
          </div>
        );
      },
    },
    ...(type === "Advance Request" || type === "Submitted"
      ? [
          {
            field: "documents_status",
            headerName: "Documents Status",
            flex: 1.2,

            cellRenderer: (params) => {
              const rowId = params?.data?._id; // Adjust this to match your row ID field
              const documentStatus = pandaDocStatus.find(
                (entry) => entry[rowId] !== undefined,
              );
              return (
                <div className="d-flex align-items-center gap-3">
                  <div
                    title={
                      params?.data?.documentStatus === "document.completed"
                        ? "Document Completed"
                        : params?.data?.documentStatus === "document.viewed"
                        ? "Document Viewed"
                        : params?.data?.documentStatus === "document.uploaded"
                        ? "Document Uploaded"
                        : params?.data?.documentStatus === "document.sent"
                        ? "Document Sent"
                        : params?.data?.documentStatus
                        ? params?.data?.documentStatus
                        : ""
                    }
                  >
                    {params?.data?.documentStatus === "document.completed"
                      ? "Document Completed"
                      : params?.data?.documentStatus === "document.viewed"
                      ? "Document Viewed"
                      : params?.data?.documentStatus === "document.uploaded"
                      ? "Document Uploaded"
                      : params?.data?.documentStatus === "document.sent"
                      ? "Document Sent"
                      : params?.data?.documentStatus
                      ? params?.data?.documentStatus
                      : ""}
                  </div>

                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      // handlePandaDocsStatus(params?.row);
                      handleUpdateDocStatus({
                        data: params?.data,
                      });
                    }}
                  >
                    <Refresh size="16" />
                  </div>
                </div>
              );
            },
          },
        ]
      : []),
    ...(type === "Funded"
      ? []
      : [
          {
            field: "actions",
            headerName: "Action",
            flex: 0.6,
            cellRenderer: (params, index) => {
              return (
                <>
                  <div>
                    <span
                      type="button"
                      onClick={(event) => handleStatusMenu(event, params?.data)}
                    >
                      <HorizontalMenu />
                    </span>
                  </div>
                </>
              );
            },
          },
        ]),
  ];

  const getMenuList = () => {
    const getMenuByRoleDraft = () => {
      if (roleData?.["add/edit"] && roleData?.view) {
        return menu[0];
      } else if (roleData?.view) {
        return [menu[0][1]];
      } else if (roleData?.["add/edit"]) {
        return [menu[0][1]];
      } else {
        return [];
      }
    };
    console.log("menu", menu);
    const getMenuByRoleAdvanceR = () => {
      if (roleData?.tabs) {
        return menu[0];
      } else if (roleData?.view) {
        return [menu[0][0]];
      } else {
        return [];
      }
    };

    switch (type) {
      case "Draft":
        return getMenuByRoleDraft();
      case "Advance Request":
        return getMenuByRoleAdvanceR();
      case "Submitted":
        return getMenuByRoleAdvanceR();
      case "Approved":
        return getMenuByRoleAdvanceR();
      case "Allocated":
        return getMenuByRoleAdvanceR();
      case "Funded":
        return getMenuByRoleDraft();
      case "Closed":
        return getMenuByRoleDraft();
      case "Archive":
        return roleData && roleData.delete && menu[0];
      default:
        return [];
    }
  };

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const CustomNoRowsOverlay = memo(() => {
    return loader ? (
      <>
        {" "}
        <div
          className="w-100  d-flex align-items-center justify-content-center"
          style={{ height: "70vh" }}
        >
          <span
            className="spinner-border spinner-border-lr ms-2"
            style={{ color: "#0d6efd", width: "50px", height: "50px" }}
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </>
    ) : (
      <>
        <NoContentCard
          title={content[type]?.title}
          subtitle={content[type]?.subtitle}
          type="Pricing"
          classes="d-flex align-items-center"
        />
      </>
    );
  });

  return loader ? (
    <div
      className="w-100  d-flex align-items-center justify-content-center"
      style={{ height: "70vh" }}
    >
      <span
        className="spinner-border spinner-border-lr ms-2"
        style={{ color: "#3b424a", width: "40px", height: "40px" }}
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  ) : (
    <>
      <CustomMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        // items={menu?.[0]}
        items={getMenuList()}
      />
      <CustomDatagrid
        rows={fundingData?.[0]?.fundingData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        // tHeight="76vh"
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        isRowsVisible
        isValueVisible
        // checkRequired
        // setSelectedRowIds={setSelectedRowIds}
        onCellClick={
          type === "Draft" || type === "Funded" || type === "Closed"
            ? (data) => {
                if (
                  data?.colDef?.field !== "actions" &&
                  data?.colDef?.field !== "__check__" &&
                  data?.colDef?.colId !== "ag-Grid-SelectionColumn"
                ) {
                  if (type === "Draft") {
                    navigate(
                      `/dashboard/funding/draft/view/${data?.data?._id}`,
                    );
                  } else if (type === "Funded") {
                    navigate(`/dashboard/funding/view/${data?.data?._id}`, {
                      state: { tab: type },
                    });
                  } else if (type === "Closed") {
                    navigate(`/dashboard/funding/view/${data?.data?._id}`, {
                      state: { tab: type },
                    });
                  }
                }
              }
            : undefined
        }
      />
      {/* {fundingData?.[0]?.pageDetails?.count > 10 && ( */}
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={fundingData?.[0]?.pageDetails?.count}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
        options={[400, 600, 800, 1000]}
      />
      {/* )} */}
      <Modal
        isActive={modalStateArchive.isModalOpen}
        onClose={handleCloseArchive}
        onClick={handleArchive}
        title={"Move to Rejected"}
        name={modalStateArchive.name}
        type=""
        description={
          <span>
            Are you sure you want to move the funding for{" "}
            <strong>{modalStateArchive?.name}</strong> to rejected?
          </span>
        }
      />
      <Modal
        isActive={modalStateDelete.isModalOpen}
        onClose={handleCloseDelete}
        onClick={handleDelete}
        title={modalStateDelete?.name}
        name={modalStateDelete.name}
        type="Delete"
      />
    </>
  );
};

export default Table;
