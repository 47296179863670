import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { PDFRenderer } from "react-doc-viewer";
import FileViewer from "react-file-viewer";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";
import { NumberFormatAndAddZero } from "../../../../../../helpers/capatalizeLetters";
import { patchData } from "../../../../../../services";
import {
  getPandaApi,
  getPandaData,
} from "../../../../../../services/pandaDocs";
import Refresh from "../../../../../icons/Refresh";
import style from "../index.module.scss";

const Schedule = ({
  fundedDataById,
  activeTab,
  pandaDocStatus,
  handlePandaDocsStatus,
  setFundedDataById,
}) => {
  const [pdfData, setPdfData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cookies] = useCookies(["t", "roleGroup"]);
  const [isDownload, setIsDownload] = useState(false);

  const getScheduleDocuments = async () => {
    try {
      setIsLoading(true);
      const url = `https://api.pandadoc.com/public/v1/documents/${fundedDataById?.documentsInvoice?.docInvoiceId}/download`;

      const response = await getPandaData({
        endpoint: url,
        params: {},
        token: process.env.REACT_APP_PANDADOC_ID,
      });
      const blob = new Blob([response?.data], { type: "application/pdf" });
      setPdfData(URL.createObjectURL(blob));
      setIsLoading(false);
    } catch (err) {
      console.log("error documents", err);
    }
  };

  const handlePandaDocsStatus2 = async (data) => {
    const response = await getPandaApi({
      endpoint: `https://api.pandadoc.com/public/v1/documents/${fundedDataById?.documentsInvoice?.docInvoiceId}`,
      params: {},
      token: process.env.REACT_APP_PANDADOC_ID,
    });

    return response;
  };

  const handleUpdateDocStatus = async () => {
    const documentStatus = await handlePandaDocsStatus2();

    if (!documentStatus?.status) {
      console.error("Invalid data: Missing Status");
      return;
    }

    try {
      const res = await patchData({
        endpoint: "/funding/UpdateFundingDocStatus",
        token: cookies.t,
        data: { documentStatus: documentStatus.status },
        params: { id: fundedDataById._id },
      });

      if (res) {
        setFundedDataById({
          ...fundedDataById,
          documentStatus: res?.documentStatus,
        });
        toast.success("Document Status Refreshed", {
          duration: 1000,
          style: confimationStyles,
        });
      } else {
        console.error("Failed to update document: No response data");
      }
    } catch (error) {
      console.error("Error updating document:", error);
    } finally {
      // setLoader(false);
    }
  };

  const handleDownloadInvoice = async (invoice) => {
    setIsDownload(true);
    console.log("invoice", invoice);
    const url = `https://api.pandadoc.com/public/v1/documents/${fundedDataById?.documentsInvoice?.docInvoiceId}/download`;

    const response = await getPandaData({
      endpoint: url,
      params: {},
      token: process.env.REACT_APP_PANDADOC_ID,
    });
    const blob = await new Blob([response?.data], { type: "application/pdf" });
    const tempurl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = tempurl;
    link.download = "document.pdf"; // Set the desired filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    window.URL.revokeObjectURL(tempurl);
    setIsDownload(false);
  };

  useEffect(() => {
    if (
      fundedDataById?.documentsInvoice?.docInvoiceId &&
      activeTab === "Schedule"
    ) {
      getScheduleDocuments();
      handlePandaDocsStatus("initial");
    }
    if (pdfData) {
      URL.revokeObjectURL(pdfData); // Clean up the URL when the component unmounts
    }
  }, [fundedDataById?.docInvoiceId]);

  return (
    <>
      <div className={style.bothsidewrap}>
        <div className={style.fundedViewleftWrap}>
          <table class="table">
            <thead>
              <tr>
                <th scope="col" colSpan={2}>
                  Asset Information
                </th>
                {/* <th scope="col"></th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={style.label}>Transaction Type</td>
                <td className={style.value}>
                  {fundedDataById?.transaction_type === "Obligor"
                    ? "FA"
                    : fundedDataById?.transaction_type}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Client Invoice ID/Ref</td>
                <td className={style.value}>
                  <div className="word-break-global">
                    {fundedDataById?.invoice_number ||
                      fundedDataById?.referance}
                  </div>
                </td>
              </tr>
              <tr>
                <td className={style.label}>Client Name</td>
                <td className={style.value}>{fundedDataById?.client_name}</td>
              </tr>
              {
                <tr>
                  <td className={style.label}>Obligor Name</td>
                  <td className={style.value}>
                    {fundedDataById?.obligor_name
                      ? fundedDataById?.obligor_name
                      : fundedDataById?.client_name}
                  </td>
                </tr>
              }
              {
                <tr>
                  <td className={style.label}>Supplier Name</td>
                  <td className={style.value}>
                    {fundedDataById?.supplier_name
                      ? fundedDataById?.supplier_name
                      : fundedDataById?.client_name}
                  </td>
                </tr>
              }
              <tr>
                <td className={style.label}>Term</td>
                <td className={style.value}>{fundedDataById?.payment_terms}</td>
              </tr>
              {fundedDataById?.req_advance_value && (
                <tr>
                  <td className={style.label}>Requested Advance Value</td>
                  <td className={style.value}>
                    {NumberFormatAndAddZero(fundedDataById?.req_advance_value)}
                  </td>
                </tr>
              )}
              <tr>
                <td className={style.label}>Cost</td>
                <td className={style.value}>
                  {NumberFormatAndAddZero(fundedDataById?.cost_value)}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Face Value</td>
                <td className={style.value}>
                  {NumberFormatAndAddZero(
                    parseFloat(fundedDataById?.face_value),
                  )}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Currency</td>
                <td className={style.value}>{fundedDataById?.currency}</td>
              </tr>
              <tr>
                <td className={style.label}>Invoice Date</td>
                <td className={style.value}>
                  {" "}
                  {fundedDataById?.invoice_date
                    ? moment
                        .utc(fundedDataById?.invoice_date)
                        .format("YYYY-MM-DD")
                    : ""}
                </td>
              </tr>
              {fundedDataById?.due_date && (
                <tr>
                  <td className={style.label}>Due Date</td>
                  <td className={style.value}>
                    {moment.utc(fundedDataById?.due_date).format("YYYY-MM-DD")}
                  </td>
                </tr>
              )}
              <tr>
                <td className={style.label}>Funding Date</td>
                <td className={style.value}>
                  {moment.utc(fundedDataById?.funded_date).format("YYYY-MM-DD")}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Participant</td>
                <td className={style.value}>
                  {fundedDataById?.funder ? fundedDataById?.funder : "--"}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Status</td>
                <td className={style.value}>{fundedDataById?.status}</td>
              </tr>
              <tr>
                <td className={`${style.label}`}>
                  Schedule Status
                  {/* <CustomButton
                    text="Refresh"
                    type="btn-primary"
                    size="btn-sm"
                    style={{ marginLeft: "10px" }}
                    handleClick={() => {
                      handlePandaDocsStatus();
                    }}
                  /> */}
                </td>
                <td className={style.value}>
                  <div className="d-flex align-items-center gap-3">
                    {/* {pandaDocStatus?.status ? pandaDocStatus?.status : "--"} */}
                    {fundedDataById?.documentStatus
                      ? fundedDataById?.documentStatus
                      : ""}
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        // handlePandaDocsStatus();
                        handleUpdateDocStatus();
                      }}
                    >
                      <Refresh size="18" />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={style.fundedViewRightWrap}>
          {isLoading ? (
            <>
              <div class="d-flex justify-content-center mt-4">
                <div role="status">
                  <span
                    className="spinner-border spinner-border-lg ms-5 text-black"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              </div>
            </>
          ) : (
            <>
              {pdfData && (
                <>
                  <div className="mt-2 mb-2 d-flex justify-content-between align-items-center">
                    <div>
                      <strong>Invoice</strong>
                    </div>
                    <button
                      className="btn-primary p-2"
                      onClick={() => handleDownloadInvoice(pdfData)}
                      style={{ borderRadius: "4px" }}
                    >
                      Download
                      {isDownload && (
                        <span
                          className="spinner-border spinner-border-sm ms-2 text-light"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </button>
                  </div>
                  <FileViewer
                    fileType="pdf"
                    filePath={pdfData}
                    pluginRenderers={PDFRenderer}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default Schedule;
