import moment from "moment/moment";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import { NumberFormatAndAddZero } from "../../../../helpers/capatalizeLetters";
import { getData } from "../../../../services";
import { downloadReport } from "../../../../services/downloadService";
import { CurrencyData } from "../../../../utils/staticData";
import CustomButton from "../../../atoms/CustomButton";
import { Heading } from "../../../atoms/Heading";
import { DateSelector } from "../../../atoms/MonthYearSelector";
import MyDropdown from "../../../atoms/MyDropdown";
import NoContentCard from "../../../atoms/NoContentCard";
import Pagination from "../../../atoms/Pagination";
import Search from "../../../atoms/Search";
import Upload from "../../../icons/Upload";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import style from "./index.module.scss";

const ReportsICY = () => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Counterparties;
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [counterpartyData, setCounterPartData] = useState([]);
  const [investorData, setInvestorData] = useState([]);
  const [formData, setFormData] = useState({
    fund: "",
    date: "",
    currency: "",
  });
  const [filters, setFilters] = useState({});
  const [loader, setLoader] = useState(true);

  const handleConfirmSelection = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "funding/getAllFundingsByIcy",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          status: ["Funded"],
          currency: formData?.currency?.toLowerCase() || "usd",
          transactionType: filters?.["Transaction Type"],
          dueStartDate: filters?.startDate0,
          dueEndDate: filters?.endDate0,
          fundingStartDate: filters?.startDate1,
          fundingEndDate: filters?.endDate1,
          investorID: formData.fund,
          as_of_date: formData.date ? formData.date : new Date(),
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        },
      });
      setCounterPartData(res?.data);
    } catch (err) {
      console.log("errr", err);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (formData.fund || formData.date) {
      handleConfirmSelection();
    }
  }, [itemsPerPage, currentPage, searchData, filters]);

  const getInvestorData = async () => {
    try {
      const res = await getData({
        endpoint: "investor/getInvestorList",
        token: cookies.t,
      });
      setInvestorData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    setLoader(false);
    getInvestorData();
  }, []);

  const columns = [
    {
      field: "client_name",
      headerName: "Client Name",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return <div className="line-truncate">{params?.data?.client_name}</div>;
      },
    },
    {
      field: "obligor_name",
      headerName: "Obligor Name",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div className="line-truncate">
            {params?.data?.client_id === params?.data?.obligor_id
              ? params?.data?.client_name
              : params?.data?.obligor_name
              ? params?.data?.obligor_name
              : params?.data?.client_name
              ? params?.data?.client_name
              : ""}
          </div>
        );
      },
    },
    {
      field: "supplier_name",
      headerName: "Supplier Name",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div className="line-truncate">
            {params?.data?.client_id === params?.data?.supplier_id
              ? params?.data?.client_name
              : params?.data?.supplier_name
              ? params?.data?.supplier_name
              : params?.data?.client_name
              ? params?.data?.client_name
              : ""}
          </div>
        );
      },
    },
    {
      field: "Fund Date",
      headerName: "Fund Date",
      flex: 1.2,
      sortable: true,
      valueGetter: (params) => params?.data?.funded_date || null,
      valueFormatter: (params) =>
        params.value ? moment.utc(params.value).format("YYYY-MM-DD") : "",
      cellRenderer: (params) => (
        <div className={style.valueStyle}>
          {params.value ? moment.utc(params.value).format("YYYY-MM-DD") : ""}
        </div>
      ),
    },
    {
      field: "Due Date",
      headerName: "Due Date",
      flex: 1.2,
      sortable: true,
      valueGetter: (params) => params?.data?.due_date || null,
      valueFormatter: (params) =>
        params.value ? moment.utc(params.value).format("YYYY-MM-DD") : "",
      cellRenderer: (params) => (
        <div className={style.valueStyle}>
          {params.value ? moment.utc(params.value).format("YYYY-MM-DD") : ""}
        </div>
      ),
    },

    {
      field: "Currency",
      headerName: "Currency",
      flex: 1,
      sortable: true,
      valueGetter: (params) => params?.data?.currency || "",
      valueFormatter: (params) => params.value || "",
      cellRenderer: (params) => (
        <div className={style.valueStyle}>{params.value}</div>
      ),
    },
    {
      field: "cost_value",
      headerName: "Cost Value",
      type: "number",
      sortable: true,
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => params?.data?.cost_value || 0,
      valueFormatter: (params) =>
        params.value ? NumberFormatAndAddZero(params.value) : "0",
      cellRenderer: (params) => (
        <div className={style.valueStyle}>
          {NumberFormatAndAddZero(params.value)}
        </div>
      ),
    },
    {
      field: "Fund",
      headerName: "Fund",
      flex: 1,
      sortable: true,
      valueFormatter: (param, row) => {
        return param?.row?.funder;
      },
      cellRenderer: (params) => {
        return <div className={style.valueStyle}>{params?.data?.funder}</div>;
      },
    },
    {
      field: "Date",
      headerName: "Date",
      flex: 1,
      sortable: true,
      valueGetter: (params) => formData.date || new Date(),
      cellRenderer: (params) => {
        const date = moment(params.value).format("YYYY-MM-DD");
        return <span>{date}</span>;
      },
    },
    {
      field: "Principal Remaining",
      headerName: "Principal Remaining",
      flex: 1,
      sortable: true,
      type: "number",
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => {
        return params?.data?.principle_remaining || 0;
      },
      cellRenderer: (params) => {
        return <span>{NumberFormatAndAddZero(params.value) || 0}</span>;
      },
    },
    {
      field: "Principal Paid",
      headerName: "Principal Paid",
      sortable: true,
      type: "number",
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => {
        return params?.data?.principle_paid || 0;
      },
      cellRenderer: (params) => {
        return <span>{NumberFormatAndAddZero(params.value) || 0}</span>;
      },
    },
    {
      field: "Interest Paid",
      headerName: "Interest Paid",
      sortable: true,
      type: "number",
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => {
        return params?.data?.intrest_paid || 0;
      },
      cellRenderer: (params) => {
        return <span>{NumberFormatAndAddZero(params.value) || 0}</span>;
      },
    },
    {
      field: "Interest Accrued",
      headerName: "Interest Accrued",
      sortable: true,
      type: "number",
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => {
        return params?.data?.intrest_accured || 0;
      },
      cellRenderer: (params) => {
        return <span>{NumberFormatAndAddZero(params.value) || "0"}</span>;
      },
    },
    {
      field: "Amount to Close",
      headerName: "Amount to Close",
      flex: 1,
      sortable: true,
      type: "number",
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => params?.data?.amount_to_close || 0,
      cellRenderer: (params) => (
        <span>{NumberFormatAndAddZero(params.value) || ""}</span>
      ),
    },
  ];

  const CustomNoRowsOverlay = memo(() => {
    return (
      !loader && (
        <>
          <NoContentCard
            title="No Accrual(Icy) created yet!"
            tag="Add Counterparties"
            type="Counterparty"
            classes="d-flex align-items-center"
          />
        </>
      )
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "funding/exportIcy",
      token: cookies.t,
      fileName: "Accural_Icy",
      params: {
        search: searchData,
        status: ["Funded"],
        currency: formData?.currency?.toLowerCase() || "usd",
        transactionType: filters?.["Transaction Type"],
        dueStartDate: filters?.startDate0,
        dueEndDate: filters?.endDate0,
        fundingStartDate: filters?.startDate1,
        fundingEndDate: filters?.endDate1,
        investorID: formData.fund,
        as_of_date: formData.date ? formData.date : new Date(),
        group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
      },
    });

    if (res) {
      toast.success("Accural Icy Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className="mb-3">
        <Heading className="HeadingStyle">Accrual(Icy)</Heading>
      </div>
      <div className="mb-4 d-flex gap-3">
        <div className="d-flex gap-3 w-100">
          <div
            style={{
              width: "30%",
              position: "relative",
            }}
          >
            <MyDropdown
              data={investorData}
              placeholder="Select Fund"
              onChange={(option) => {
                setFormData({
                  ...formData,
                  fund: option,
                });
              }}
              selectedValue={formData.fund}
            />
          </div>
          <div
            style={{
              width: "30%",
              position: "relative",
            }}
          >
            <MyDropdown
              data={CurrencyData}
              placeholder="Select Currency"
              onChange={(option) => {
                setFormData({
                  ...formData,
                  currency: option,
                });
              }}
              selectedValue={formData.currency}
            />
          </div>
          <div
            style={{
              width: "30%",
              position: "relative",
            }}
          >
            <DateSelector
              placeholder="As of Date"
              onChange={(option) => {
                setFormData({
                  ...formData,
                  date: option,
                });
              }}
              selectedDate={formData.date}
            />
          </div>
        </div>
        <div>
          <CustomButton
            text="Confirm Selection"
            type="btn-outline-primary"
            size="btn-lg"
            style={{ minWidth: "max-content" }}
            handleClick={() => handleConfirmSelection()}
          />
        </div>
      </div>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Accrual(Icy) List</Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            placeholder="Search by name"
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />

          {roleData && roleData?.export && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}
          <FilterComponent
            isDateRange
            dateRangeNames={["Due Date", "Funding Date"]}
            onFilterChange={(filters) => {
              setFilters(filters);
            }}
          />
        </div>
      </div>

      <CustomDatagrid
        rows={counterpartyData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        loader={loader}
        isRowsVisible
        isValueVisible
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />

      {/* {counterpartyData?.[0]?.pageDetails?.count > 10 && ( */}
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={counterpartyData?.[0]?.pageDetails?.count}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
      {/* )} */}
    </>
  );
};

export default ReportsICY;
